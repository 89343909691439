<template>
  <div class="body">
    <!-- 头部导航 -->
    <top></top>
    <!-- 广告图 -->
    <div class="advertisement_box">
      <div class="advertisement">
        <img :src="ColumnImgSrc" alt="" />
      </div>
    </div>
    <!-- 搜索框 -->
    <div :class="scrollTop >= 130 ? 'fixedSearch' : ''" class="seek">
      <div
        :class="scrollTop >= 140 ? 'fixedSearchNext' : ''"
        class="seek-input"
      >
        <div class="seek-box">
          <div class="seek-one">
            <div class="seek-one-icon">
              <img src="../assets/home/seek.png" alt="" />
            </div>
            <div class="seek-one-input">
              <input
                type="font"
                placeholder="搜索您想要的内容"
                v-model="keywords"
                @keyup.enter="toSearch"
              />
            </div>
            <div class="seek-one-button">
              <button @click="toSearch">搜索</button>
            </div>
          </div>
        </div>
        <!-- 搜索框下面的文字 :class="scrollTop > 150 ? 'disNone' : ''"  -->
        <div class="seek_input_font">
          <ul>
            <li
              v-for="(item, index) in Populartags"
              :key="index"
              @click="hotTg(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 当前位置 -->
    <div class="place_box">
      <div class="place">
        <div class="place_image">
          <img src="../assets/Search_page/109.png" alt="" />
        </div>
        <!-- 位置 -->
        <div class="place_font">
          <p>当前位置：</p>
          <p>行业资讯</p>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content_box">
      <div class="contentneworder">
        <!-- 选项卡 -->
        <div class="fenlei">
          <p class="fenlei-a">全部分类</p>
          <div class="bbbb">
            <p
              v-for="(item, index) in InforTitleData"
              :key="index"
              @click="toSelect(index, item.id)"
              class="pointP"
            >
              <span>
                <img
                  :src="index == count ? item.imgsrcChange : item.imgsrc"
                  alt=""
                  class="titleDataImg"
                />
              </span>
              <span :class="index == count ? 'shop' : ''">{{
                item.title
              }}</span>
            </p>
          </div>
        </div>
        <!-- 选项卡电源 -->
        <div class="tab_content">
          <div class="containerorder" v-if="isHaveData">
            <div v-show="activeIndex == 0">
              <!-- 内容横条 -->
              <div
                class="one"
                v-for="item in InforlListData"
                :key="item.id"
                @click="toContent(item.id)"
              >
                <!-- 图片 -->
                <div class="one_image">
                  <img :src="item.reimg" alt="" />
                </div>
                <!-- 文字 -->
                <div class="font_content">
                  <!-- 标题 -->
                  <div class="font_title">
                    <p>{{ item.title }}</p>
                  </div>
                  <!-- 内容 -->
                  <div class="font_box">
                    <p v-html="colorkeyword(item.description)"></p>
                  </div>
                  <!-- 时间 -->
                  <div class="time">
                    <p>{{ item.updated_at }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="activeIndex == 1">2</div>
          </div>
          <div v-else class="container2">
            <div class="dataNone">
              <img src="../../public/images/datanone.png" alt="" />
              <p>未找到相关内容</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="paging" v-show="pagerState">
        <!-- <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="prev, pager, next"
          :page-count="pagerData.total_page"
          :pager="pagerData.page_size"
          :current-page.sync="pagerData.current_page"
        >
        </el-pagination> -->
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="prev, pager, next"
          :page-count="arr1.total_page"
          :pager="arr1.page_size"
          :current-page.sync="current_page"
        >
          <!-- :total="arr1.total_page" -->
        </el-pagination>
      </div>
    </div>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <bottom></bottom>
  </div>
</template>
<script>
    import {
        getInformationTitle,
        getInformationListData,
        getColumnImg,
        getInformationSearch,
        Populartags,
    } from "../http";
    export default {
        data() {
            return {
                isHaveData: true,
                keywords: "",
                current_page: 1,
                scrollTop: 0,
                activeIndex: 0,
                count: 0,
                id: "",
                //行业资讯标题
                InforTitleData: [],
                //行业资讯内容
                InforlListData: [],
                ColumnImgSrc: "",
                titleName: "运营教程",
                pagerState: true,
                arr1: {},
                p: 1,
                Populartags: [], //热门段子
            };
        },
        watch: {
            $route: {
                async handler() {
                    this.countChange();
                },
                deep: true,
            },
        },
        created: function() {
            window.scroll(0, 0);
            window.addEventListener("scroll", this.showSearch);
            this.getTheColumnChart();
            //获取行业资讯标题
            this.getInforTitle();
            //获取列表内容
            this.firstGetListData();
            //通过count改变
            this.countChange();
            // 热门标签调用
            this.hotTag();
        },
        methods: {
            // 热门标签点击事件
            hotTg(item) {
                this.keywords = item;
                this.toSearch();
                this.colorkeyword(item);
            },
            // 热门标签
            async hotTag() {
                let res = await Populartags();
                console.log("230", res);
                if (res.static == 1) {
                    this.Populartags = res.data;
                    console.log(this.Populartags);
                }
            },
            //吸顶监听
            showSearch() {
                // 获取当前滚动条向下滚动的距离
                let scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                // 当页面滚动到高度300px处时，显示搜索框
                this.scrollTop = scrollTop;
            },
            async handleSizeChange(val) {
                this.p = val;
                let res = await getInformationListData(this.id, this.p);
                this.InforlListData = res.data;
                console.log(res.data[0]);
                if (res.pager != null) {
                    this.pagerState = true;
                    this.arr1 = res.pager;
                } else {
                    this.pagerState = false;
                }
                console.log(`每页 ${val} 条`);
            },
            async handleCurrentChange(val) {
                this.p = val;
                let res = await getInformationListData(this.id, this.p);
                this.InforlListData = res.data;
                console.log(res.data[0]);
                if (res.pager != null) {
                    this.pagerState = true;
                    this.arr1 = res.pager;
                } else {
                    this.pagerState = false;
                }
                console.log(`当前页: ${val}`);
            },
            //第一次调取列表信息
            async firstGetListData() {
                await this.getInforTitle();
                this.id = this.InforTitleData[0].id;
                await this.getInforlListData(this.id);
                console.log(this.id);
            },
            //获得栏目图
            async getTheColumnChart() {
                let imgSrc = await getColumnImg();
                this.ColumnImgSrc = imgSrc;
            },
            //获取行业资讯标题
            async getInforTitle() {
                let InforTitleData = await getInformationTitle();
                // console.log(InforTitleData,'赋值成功');
                for (let i = 0; i < InforTitleData.length; i++) {
                    if (i % 2 == 0) {
                        InforTitleData[
                            i
                        ].imgsrc = require("../assets/information/info196.png");
                        InforTitleData[
                            i
                        ].imgsrcChange = require("../assets/information/info114.png");
                    } else {
                        InforTitleData[
                            i
                        ].imgsrc = require("../assets/information/info115.png");
                        InforTitleData[
                            i
                        ].imgsrcChange = require("../assets/information/info197.png");
                    }
                }
                this.InforTitleData = InforTitleData;
                if (this.$route.query.count >= 0) {
                    this.titleName = this.InforTitleData[this.$route.query.count].title;
                } else {
                    this.titleName = this.InforTitleData[0].title;
                }
            },
            // count处理
            async countChange() {
                if (this.$route.query.count >= 0) {
                    let count = this.$route.query.count;
                    this.count = count;
                    await this.getInforTitle();
                    // console.log(this.InforTitleData[count].id,"跳转进来的，打印");
                    console.log(this.InforlListData);
                    await this.getInforlListData(this.InforTitleData[count].id);
                    console.log();
                } else {
                    // console.log("不是跳转进来，不打印");
                    return;
                }
            },
            //点击获取列表内容
            async getInforlListData(id) {

                let res = await getInformationListData(id, this.p);
                this.InforlListData = res.data;

                console.log(res);
                if (res.pager != null) {
                    this.pagerState = true;
                    this.arr1 = res.pager;
                    // this.current_page = res.pager.current_page-0;
                } else {
                    this.pagerState = false;
                }
            },
            //跳转到详情页
            toContent(id) {
                this.$router.push({
                    path: "/Content",
                    query: {
                        page: 5,
                        id,
                        count: this.count,
                        type: 1,
                    },
                });
            },
            //去选择标题
            async toSelect(index, id) {
                this.count = index;
                // this.titleName = index==1?'运营教程':'';
                this.current_page = 1;
                this.p = 1;
                console.log(index, id);
                this.id = id;
                this.getInforlListData(id);
            },
            //去搜索
            async toSearch() {
                this.p = 1;
                let content = await getInformationSearch(this.p, this.keywords);
                // console.log(content.data[0].type);
                if (content.stutic == 0) {
                    console.log(content.msg);
                    this.isHaveData = false;
                } else {
                    this.InforlListData = content.data;
                    this.isHaveData = true;
                    if (content.pager != null) {
                        this.pagerState = true;
                        this.arr1 = content.pager;
                    } else {
                        this.pagerState = false;
                    }
                }
                // console.log(content);
                // let id = content.id;
                // this.$router.push({
                //   path: "/Content",
                //   query: {
                //     id,
                //   },
                // });
            },
        },
    };
</script>

<style scoped>
    /* input吸顶效果 */
    
    .fixedSearch {
        position: fixed;
        z-index: 2;
        height: 82px !important;
        background: rgb(255, 255, 255);
        top: 60px;
    }
    
    .fixedSearchNext {
        height: 90px !important;
    }
    
    .disNone {
        display: none;
    }
    /* 吸顶效果结束 */
    /* 页面 */
    
    .titleDataImg {
        vertical-align: middle;
        margin-right: 5px;
    }
    
    .body {
        width: 100%;
        min-width: 1200px;
        background-color: #fafafa;
    }
    /* 头部 */
    
    .pointP {
        cursor: pointer;
    }
    
    .wei_e>ul>li {
        color: #f62959;
        border-bottom: 3px #f62959 solid;
    }
</style>
<style scoped>
    /* 广告 */
    
    .advertisement_box {
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding-top: 70px;
        background-color: #ffffff;
    }
    
    .advertisement {
        width: 1200px;
        margin: auto;
    }
    
    .advertisement img {
        width: 1200px;
    }
    /* 搜索 */
    
    .seek {
        width: 100%;
        height: 130px;
        min-width: 1200px;
        background-color: #ffffff;
    }
    
    .seek-input {
        width: 1200px;
        height: 130px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .seek-box {
        width: 750px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    
    .seek-one {
        display: flex;
        align-items: center;
        border: 1px #f62959 solid;
        border-radius: 8px;
    }
    
    .seek-one-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }
    
    .seek-one-icon img {
        width: 100%;
    }
    
    .seek-one-input {
        width: 590px;
        display: inline-block;
    }
    
    .seek-one-input input {
        width: 99%;
        line-height: 38px;
        font-size: 16px;
        color: #999999;
        vertical-align: middle;
        background: none;
    }
    
    .seek-one-input input:checked {
        color: #999999;
    }
    
    .seek-one-button {
        width: 114px;
        height: 40px;
    }
    
    .seek-one-button button {
        width: 114px;
        height: 40px;
        background-color: #f62959;
        color: #ffffff;
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
        font-size: 18px;
    }
    
    .seek_input_font {
        width: 540px;
        position: relative;
        top: -10px;
    }
    
    .seek_input_font ul {
        display: flex;
        justify-content: space-between;
    }
    
    .seek_input_font ul li {
        cursor: pointer;
        font-size: 14px;
        color: #999999;
    }
    
    .seek_input_font ul li:hover {
        color: #f62959;
    }
    /* 当前位置 */
    
    .place_box {
        width: 100%;
        min-width: 1200px;
        height: 60px;
        line-height: 60px;
        border-top: 1px #ededed solid;
    }
    
    .place {
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: auto;
        display: flex;
    }
    /* 图标 */
    
    .place_image img {
        vertical-align: middle;
    }
    /* 位置文字 */
    
    .place_font {
        display: flex;
    }
    
    .place_font p {
        margin-left: 10px;
        color: #999999;
        font-size: 14px;
    }
    
    .place_font p:nth-of-type(n + 2) {
        cursor: pointer;
    }
    /* 内容 */
    
    .content_box {
        width: 100%;
        min-height: 400px;
        min-width: 1200px;
        padding-bottom: 100px;
        position: relative;
    }
    /* 内容 */
    
    .contentneworder {
        width: 1200px;
        margin: auto;
        display: flex;
    }
    /* tab选项卡 */
    
    .fenlei {
        width: 200px;
    }
    
    .fenlei-a {
        font-size: 19px;
        font-weight: 600;
    }
    
    .bbbb {
        width: 150px;
        height: 100px;
        /* border: 1px solid red; */
        font-size: 16px;
        font-weight: 500;
    }
    
    .bbbb p {
        margin-top: 20px;
    }
    
    .shop {
        color: red;
    }
    /* .qaq {
  color: red;
} */
    /* 图标 */
    /* .tb {
  display: none;
}

.tba {
  display: none;
} */
    
    .ab {
        display: none;
    }
    /* .abb {
  display: none;
} */
    
    .btnAtive {
        background: none;
        color: #f62959;
    }
    
    .containerorder {
        width: 97%;
        max-width: 938px;
    }
    
    .container2 {
        width: 97%;
        max-width: 938px;
    }
    
    .containerorder>div {
        width: 100%;
        border-radius: 10px;
    }
    
    .dataNone {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: 34px;
        font-size: 16px;
        margin-left: 100px;
    }
    
    .dataNone p {
        text-align: center;
    }
    /* 内容横条 */
    
    .one {
        width: 945px;
        height: 240px;
        padding: 0px 0px 0px 30px;
        background: white;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
    }
    
    .one:hover {
        background-color: #f0f0f0;
    }
    
    .one_image {
        width: 260px;
        height: 180px;
    }
    
    .one_image img {
        width: 260px;
        height: 180px;
    }
    /* 文字 */
    
    .font_content {
        width: 100%;
        height: 180px;
        margin-left: 30px;
    }
    /* 标题 */
    
    .font_title p {
        width: 500px;
        height: 50px;
        font-size: 20px;
        color: #333333;
        font-weight: bolder;
        /* 省略号 */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .font_title p:hover {
        color: #f62959;
    }
    /* 文字内容 */
    
    .font_box {
        width: 100%;
        height: 96px;
    }
    
    .font_box p {
        width: 600px;
        font-size: 14px;
        color: #666666;
    }
    /* 时间 */
    
    .time {
        width: 100%;
        line-height: 30px;
    }
    
    .time p {
        font-size: 14px;
        color: #999999;
    }
    /* 分页 */
    
    .paging {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
<style>
    .active {
        border: none !important;
    }
    
    .btn-prev {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-prev:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .btn-prev>i::before {
        content: "上一页" !important;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Medium !important;
    }
    
    .btn-next {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-next:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .btn-next>i::before {
        content: "下一页" !important;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Medium !important;
    }
    
    .el-pager>li {
        width: 32px !important;
        height: 32px !important;
        border: 1px solid #cccccc !important;
        border-radius: 50% !important;
    }
    
    .el-pager>li:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #f62959;
        color: #fff;
        border: none;
    }
</style>